import * as React from "react";
import Layout from "./src/components/layout";
import CookieConsent from "react-cookie-consent";
import { setTrackingCookie, startTracking } from "./src/components/tracking";
import Cookies from "js-cookie";
import { useLocation } from "@reach/router";

export const wrapPageElement = ({ element, props }) => {
  const location = useLocation();
  const [consented, setConsent] = React.useState(
    // make the dependency on the "CookieConsent" cookie.
    // ga-check cookie is what actually controls GA-tracking,
    // and CookieConsent cookie controls when we ask the user.
    Cookies.get("CookieConsent")
  );
  React.useEffect(() => {
    startTracking(location, consented === "true");
    if (consented == "false") {
      // if user has not consented, expire it in 1 day
      Cookies.set("CookieConsent", false, { expires: 1 });
    }
  }, [consented]);

  return (
    <Layout {...props}>
      {element}
      <CookieConsent
        enableDeclineButton
        onDecline={() => {
          setTrackingCookie(false);
          setConsent("false");
        }}
        onAccept={(acceptedByScrolling) => {
          if (acceptedByScrolling) {
            // this is not actualy an accept
          } else {
            setTrackingCookie(true);
            setConsent("true");
          }
        }}
        expires={365}
        buttonText="Accept All"
        declineButtonText="Accept Necessary"
        declineButtonStyle={{
          background: "rgba(21, 65, 237, 0)",
          color: "white",
          border: "1px solid white",
        }}
        buttonStyle={{
          background: "rgb(21, 65, 237)",
          color: "white",
          border: "1px solid white",
        }}
      >
        This website uses cookies for marketing insights. You can find out more
        by reading our{" "}
        <a
          style={{ textDecoration: "underline" }}
          href="https://waybridge.com/privacy/"
        >
          Privacy Policy
        </a>
        .
      </CookieConsent>
    </Layout>
  );
};
