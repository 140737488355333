import React from "react";
import Logo from "./logo";
import { Link } from "gatsby";
import { A5, A8 } from "./fonts";
import Container from "../components/container";

const NewsLetterSignup = () => {
  return (
    <div id="mc_embed_signup">
      <form
        action="https://waybridge.us3.list-manage.com/subscribe/post?u=34010a6fde500d0a8314acc2f&amp;id=fb666924f3"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
        rel="noopener"
        noValidate
      >
        <input
          className="block text-h5 antialiased blur-none focus:outline-none appearance-none bg-transparent text-wbSand placeholder-wbSand border-b border-wbSand w-full py-1 leading-5"
          placeholder="Email address"
          type="email"
          name="EMAIL"
          id="mce-EMAIL"
        />

        <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_34010a6fde500d0a8314acc2f_fb666924f3"
            tabIndex="-1"
          />
        </div>

        <div className="pt-4">
          <button
            type="submit"
            name="subscribe"
            id="mc-embedded-subscribe"
            className="antialiased text-h5 text-wbStone50 hover:text-wbSand transition duration-300 ease-in-out"
          >
            Submit →
          </button>
        </div>
      </form>
    </div>
  );
};

const Footer = () => {
  return (
    <Container className="bg-wbTruss text-wbSand relative z-50">
      <div className="col-span-30 px-6 md:col-span-10 lg:pr-20">
        <div className="flex mt-12">
          <Link to="/">
            <Logo className="fill-current text-wbSand max-h-16 w-auto" />
          </Link>
        </div>
      </div>
      <div className="col-span-30 px-6 md:col-span-20">
        <A5 className="mt-12 max-w-md">
          Subscribe for updates on our product, funding, and progress.
        </A5>

        {/* EMAIL SIGN UP */}

        <div className="pt-6">
          <NewsLetterSignup />
        </div>
      </div>

      <div className="col-span-30 px-6 md:col-span-10">
        <div className="flex flex-row mt-20 border-wbStone50 text-wbStone50 border-t pt-3">
          <div className="flex-1 max-w-xs text-meta font-medium antialiased">
            Waybridge is a transformational new operating system for raw
            materials supply chains.
          </div>
        </div>
      </div>

      <div className="col-span-30 px-6 md:col-span-20">
        {/* LINKS */}

        <div className="flex flex-row mt-20 border-wbStone50 border-t pt-3">
          <div className="flex-1">
            <A8 className="pt-0 text-wbStone50 hover:text-wbSand transition duration-300 ease-in-out">
              <a href="/">Home</a>
              <br />
            </A8>
            <A8 className="pt-0.5 text-wbStone50 hover:text-wbSand transition duration-300 ease-in-out">
              <Link to="/platform">Platform</Link>
            </A8>
            <A8 className="pt-0.5 text-wbStone50 hover:text-wbSand transition duration-300 ease-in-out">
              <a href="/about">About</a>
            </A8>
            {/* <A8 className="pt-0.5 text-wbStone50 hover:text-wbSand transition duration-300 ease-in-out">
              <a href="/careers">Careers</a>
            </A8> */}
            <A8 className="pt-0.5 text-wbStone50 hover:text-wbSand transition duration-300 ease-in-out">
              <a href="/request-demo">Request Demo</a>
            </A8>
          </div>
          <div className="flex-1">
            <A8 className="pt-0 text-wbStone50 hover:text-wbSand transition duration-300 ease-in-out">
              <a href="https://medium.com/cmdty-people-matter">Blog</a>
            </A8>
            <A8 className="pt-0.5 text-wbStone50 hover:text-wbSand transition duration-300 ease-in-out">
              <Link to="/privacy">Privacy Policy</Link>
            </A8>
            <A8 className="pt-0.5 text-wbStone50 hover:text-wbSand transition duration-300 ease-in-out">
              <a href="https://www.linkedin.com/company/viawaybridge">
                LinkedIn
              </a>
            </A8>
            <A8 className="pt-0.5 text-wbStone50 hover:text-wbSand transition duration-300 ease-in-out">
              <a href="https://twitter.com/viawaybridge">Twitter</a>
            </A8>
            <A8 className="pt-0.5 text-wbStone50 hover:text-wbSand transition duration-300 ease-in-out">
              <a href="mailto:info@waybridge.com">Contact</a>
            </A8>
            <A8 className="pt-0.5 text-wbStone50 hover:text-wbSand transition duration-300 ease-in-out">
              <a
                href="https://app.suggestionox.com/r/waybridge
"
              >
                Feedback
              </a>
            </A8>
          </div>
        </div>

        <div className="flex flex-row mt-20 border-wbStone50 text-wbStone50 border-t pt-3">
          <div className="flex-1 text-meta font-medium antialiased">
            New York City
          </div>
          <div className="flex-1 text-meta antialiased">
            150 W 22nd Street
            <br />
            11th Floor
            <br />
            New York, NY 10011
            <br />
            United States
          </div>
        </div>
        <div className="flex flex-row mt-10 mb-32 border-wbStone50 text-wbStone50 border-t pt-3">
          <div className="flex-1 text-meta font-medium antialiased">London</div>
          <div className="flex-1 text-meta antialiased">
            New Kings Beam House
            <br />
            22 Upper Ground
            <br />
            London SE1 9PD
            <br />
            United Kingdom
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
