import React from "react";

export const Logo = (props) => (
  <svg
    width="505"
    height="225"
    viewBox="0 0 505 225"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M314.29,24.28L270.27,0.34c-0.52-0.28-1.12-0.39-1.7-0.31L135.62,18.71c-0.62,0.09-1.08,0.62-1.08,1.24
	l-0.14,74.29c0,0.92,0.5,1.77,1.31,2.21l43.43,23.62c0.53,0.29,1.19,0.16,1.57-0.31l23.76-28.93c0.38-0.47,1.04-0.59,1.57-0.31
	l37.63,20.47c0.53,0.29,1.19,0.16,1.57-0.31l69.42-84.52C315.16,25.57,314.98,24.66,314.29,24.28z M290.25,24l-33.03,4.64
	c-0.68,0.1-1.12-0.69-0.69-1.22l12.57-15.31c0.38-0.47,1.04-0.59,1.57-0.31l19.83,10.79C291.14,22.94,290.97,23.9,290.25,24z
	 M192,36.49l12.57-15.31c0.38-0.47,1.04-0.59,1.57-0.31l19.83,10.79c0.64,0.35,0.46,1.31-0.26,1.41l-33.03,4.64
	C192.01,37.81,191.56,37.02,192,36.49z M241.32,28.76l-19.81-10.77c-0.64-0.35-0.46-1.31,0.26-1.41l32.99-4.64
	c0.68-0.1,1.12,0.69,0.69,1.22l-12.56,15.29C242.51,28.92,241.85,29.05,241.32,28.76z M176.79,37.83l-19.81-10.78
	c-0.64-0.35-0.46-1.31,0.26-1.41l33-4.64c0.68-0.1,1.12,0.69,0.69,1.22l-12.56,15.29C177.98,37.99,177.32,38.12,176.79,37.83z
	 M170.74,46.81l-25.09,30.54c-0.45,0.55-1.34,0.23-1.34-0.48l0.08-44.14c0-0.57,0.61-0.93,1.11-0.66l25.01,13.6
	C170.93,45.89,171.04,46.44,170.74,46.81z M172.78,104.8l-24.7-13.44c-0.42-0.23-0.52-0.77-0.22-1.14l24.79-30.17
	c0.45-0.55,1.34-0.23,1.34,0.48l-0.08,43.61C173.9,104.71,173.28,105.08,172.78,104.8z M184.96,48.78l44.18-6.21
	c0.68-0.1,1.12,0.69,0.69,1.22l-44.71,54.46c-0.45,0.55-1.34,0.23-1.34-0.48l0.09-47.75C183.88,49.39,184.34,48.86,184.96,48.78z
	 M249.49,39.71l44.22-6.21c0.68-0.1,1.12,0.69,0.69,1.22l-44.74,54.47c-0.45,0.55-1.34,0.23-1.34-0.48l0.09-47.75
	C248.41,40.32,248.87,39.79,249.49,39.71z M238.51,51.5l-0.08,43.57c0,0.57-0.61,0.93-1.11,0.66l-24.68-13.43
	c-0.42-0.23-0.52-0.77-0.22-1.14l24.76-30.15C237.63,50.47,238.51,50.79,238.51,51.5z"
    />
    <path
      d="M96.89,118.27h-10.1c-0.59,0-1.11,0.42-1.23,1l-13.02,62.55c-0.16,0.79-1.29,0.8-1.47,0.01l-14.31-62.59
			c-0.13-0.57-0.64-0.98-1.22-0.98H42.01c-0.59,0-1.1,0.41-1.23,0.99l-13.34,62.52c-0.17,0.79-1.3,0.8-1.47,0l-13.66-62.53
			c-0.13-0.58-0.64-0.99-1.23-0.99H1.26c-0.81,0-1.41,0.76-1.22,1.56l19.93,81.18c0.14,0.56,0.64,0.96,1.22,0.96h10.92
			c0.59,0,1.1-0.41,1.23-0.98l14.42-64.49c0.22-0.99,1.63-0.99,1.85,0l14.64,64.5c0.13,0.57,0.64,0.98,1.22,0.98h11.82
			c0.58,0,1.08-0.4,1.22-0.96l19.6-81.18C98.3,119.03,97.7,118.27,96.89,118.27z"
    />

    <path
      d="M119.55,135.52c-14.32,0-24.71,7.64-26.35,20.65c-0.09,0.75,0.49,1.42,1.25,1.42h9.36
			c0.63,0,1.17-0.47,1.25-1.1c0.85-7.37,5.73-11.32,14.01-11.32c7.96,0,14.59,3.86,14.59,15.32v0.42c0,0.52-0.39,0.95-0.9,1
			c-24.65,2.54-40.98,7.54-40.98,22c0,11.82,7.51,19.63,20.66,19.63c9.62,0,16.47-2.88,20.54-8.36c0.41-0.56,1.29-0.32,1.36,0.37
			c0.22,2.3,0.42,3.78,0.7,5.38c0.11,0.6,0.63,1.04,1.24,1.04l7.12,0c0.69,0,1.25-0.56,1.25-1.25c0-6.52,0-36.3,0-37.7
			C144.76,143.6,136.31,135.52,119.55,135.52z M133.66,175.79c0,12.9-8.2,18.09-18.45,18.09c-7.72,0-12.88-4.31-12.88-10.22
			c0-8.18,10.77-11.51,30.28-12.44c0.57-0.03,1.05,0.43,1.05,1V175.79z"
    />

    <path
      d="M195.98,137.08h-9.19c-0.55,0-1.03,0.35-1.2,0.88l-14.33,45.24c-0.22,0.7-1.21,0.7-1.44,0l-14.67-45.25
			c-0.17-0.52-0.65-0.87-1.19-0.87h-10.01c-0.87,0-1.48,0.86-1.18,1.68l22.44,62.11l-0.75,2.36c-3.14,9.16-5.42,9.83-12.17,9.83
			h-3.69c-0.69,0-1.26,0.56-1.26,1.26v7.5c0,0.69,0.56,1.26,1.26,1.26h2.47c14.71,0,18.33-4.34,23.76-19.66l22.34-64.66
			C197.45,137.93,196.84,137.08,195.98,137.08z"
    />

    <path
      d="M231.3,135.88c-8.62,0-14.83,3.18-18.9,8.67c-0.43,0.59-1.36,0.29-1.36-0.44v-24.58
			c0-0.69-0.56-1.26-1.26-1.26h-8.43c-0.69,0-1.26,0.56-1.26,1.26v81.18c0,0.69,0.56,1.26,1.26,1.26h8.43
			c0.69,0,1.26-0.56,1.26-1.26v-5.4c0-0.73,0.93-1.03,1.36-0.44c4.06,5.49,10.28,8.67,18.9,8.67c15.08,0,27.98-12.66,27.98-33.89
			C259.27,148.42,246.37,135.88,231.3,135.88z M228.4,193.04c-9.65,0-17.85-7.36-17.85-23.39c0-16.04,8.2-23.28,17.85-23.28
			c10.49,0,17.49,8.68,17.49,23.28C245.89,184.24,238.89,193.04,228.4,193.04z"
    />

    <path
      d="M294.93,136.88c-0.78-0.03-1.58-0.03-2.29-0.03c-7.18,0.11-12.98,3.36-16.57,9.1c-0.4,0.65-1.4,0.37-1.4-0.39
			v-7.21c0-0.69-0.56-1.26-1.26-1.26h-8.43c-0.69,0-1.26,0.56-1.26,1.26v62.37c0,0.69,0.56,1.26,1.26,1.26h8.67
			c0.69,0,1.26-0.56,1.26-1.26v-32.75c0-13.63,5.07-18.33,14.71-18.33h5.26c0.69,0,1.26-0.56,1.26-1.26l0-10.24
			C296.14,137.46,295.6,136.9,294.93,136.88z"
    />

    <path
      d="M373.91,118.27h-8.43c-0.69,0-1.26,0.56-1.26,1.26l0,24.58c0,0.73-0.93,1.03-1.36,0.44
			c-4.06-5.49-10.28-8.67-18.9-8.67c-15.08,0-27.98,12.54-27.98,33.77c0,21.22,12.9,33.89,27.98,33.89c8.62,0,14.83-3.18,18.9-8.67
			c0.43-0.59,1.36-0.29,1.36,0.44l0,5.4c0,0.69,0.56,1.26,1.26,1.26h8.43c0.69,0,1.26-0.56,1.26-1.26v-81.18
			C375.16,118.83,374.6,118.27,373.91,118.27z M346.85,193.04c-10.49,0-17.49-8.8-17.49-23.39c0-14.59,7-23.28,17.49-23.28
			c9.65,0,17.85,7.24,17.85,23.28C364.7,185.69,356.5,193.04,346.85,193.04z"
    />

    <path
      d="M436.94,137.08h-8.43c-0.69,0-1.26,0.56-1.26,1.26v5.76c0,0.73-0.93,1.03-1.36,0.44
			c-4.02-5.49-10.04-8.66-18.65-8.66c-15.08,0-27.62,11.82-27.62,32.44c0,20.74,12.54,32.56,27.62,32.56
			c8.61,0,14.63-3.17,18.65-8.66c0.43-0.59,1.36-0.29,1.36,0.44v4.36c0,6.75-1.33,10.01-3.86,12.78c-2.77,3.14-7.12,4.7-13.02,4.7
			c-9.02,0-12.74-3.13-14.24-8.48c-0.15-0.55-0.64-0.93-1.21-0.93l-10.43,0c-0.8,0-1.39,0.73-1.23,1.51
			c2.58,12.58,12.82,18.39,26.87,18.39c10.01,0,17.54-2.84,22.67-7.84c4.2-4.1,5.4-10.85,5.4-22.79v-56.03
			C438.19,137.65,437.63,137.08,436.94,137.08z M410.13,190.63c-10.49,0-17.12-8.2-17.12-22.31c0-13.99,6.63-22.07,17.12-22.07
			c9.65,0,17.61,6.63,17.61,22.07C427.73,183.75,419.77,190.63,410.13,190.63z"
    />

    <path
      d="M474.51,135.88c-18.45,0-31.96,13.39-31.96,33.53c0,18.81,11.46,34.13,32.32,34.13
			c15.9,0,25.68-9.3,28.9-19.64c0.15-0.49-0.21-0.98-0.72-0.98h-10.55c-0.67,0-1.27,0.38-1.57,0.97c-2.86,5.57-7.86,8.92-16.54,8.92
			c-9.92,0-17.13-6.89-18.79-18.27c-0.09-0.6,0.39-1.14,1-1.14h47.35c0.54,0,0.98-0.43,1-0.97
			C505.86,151.78,494.22,135.88,474.51,135.88z M490.8,163.49l-33.92,0c-0.62,0-1.1-0.56-0.99-1.18
			c2.08-11.56,9.05-16.19,18.5-16.19c9.86,0,16.59,7.25,17.42,16.28C491.86,162.99,491.39,163.49,490.8,163.49z"
    />

    <path
      d="M310.27,137.08h-8.43c-0.69,0-1.26,0.56-1.26,1.26v62.37c0,0.69,0.56,1.26,1.26,1.26h8.43
			c0.69,0,1.26-0.56,1.26-1.26v-62.37C311.53,137.65,310.97,137.08,310.27,137.08z"
    />

    <path
      d="M310.27,118.27h-8.43c-0.69,0-1.26,0.56-1.26,1.26v10.03c0,0.69,0.56,1.26,1.26,1.26h8.43
			c0.69,0,1.26-0.56,1.26-1.26v-10.03C311.53,118.83,310.97,118.27,310.27,118.27z"
    />
  </svg>
);

export const LogoText = (props) => (
  <svg
    width="505"
    height="225"
    viewBox="0 0 505 225"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M96.89,118.27h-10.1c-0.59,0-1.11,0.42-1.23,1l-13.02,62.55c-0.16,0.79-1.29,0.8-1.47,0.01l-14.31-62.59
			c-0.13-0.57-0.64-0.98-1.22-0.98H42.01c-0.59,0-1.1,0.41-1.23,0.99l-13.34,62.52c-0.17,0.79-1.3,0.8-1.47,0l-13.66-62.53
			c-0.13-0.58-0.64-0.99-1.23-0.99H1.26c-0.81,0-1.41,0.76-1.22,1.56l19.93,81.18c0.14,0.56,0.64,0.96,1.22,0.96h10.92
			c0.59,0,1.1-0.41,1.23-0.98l14.42-64.49c0.22-0.99,1.63-0.99,1.85,0l14.64,64.5c0.13,0.57,0.64,0.98,1.22,0.98h11.82
			c0.58,0,1.08-0.4,1.22-0.96l19.6-81.18C98.3,119.03,97.7,118.27,96.89,118.27z"
    />

    <path
      d="M119.55,135.52c-14.32,0-24.71,7.64-26.35,20.65c-0.09,0.75,0.49,1.42,1.25,1.42h9.36
			c0.63,0,1.17-0.47,1.25-1.1c0.85-7.37,5.73-11.32,14.01-11.32c7.96,0,14.59,3.86,14.59,15.32v0.42c0,0.52-0.39,0.95-0.9,1
			c-24.65,2.54-40.98,7.54-40.98,22c0,11.82,7.51,19.63,20.66,19.63c9.62,0,16.47-2.88,20.54-8.36c0.41-0.56,1.29-0.32,1.36,0.37
			c0.22,2.3,0.42,3.78,0.7,5.38c0.11,0.6,0.63,1.04,1.24,1.04l7.12,0c0.69,0,1.25-0.56,1.25-1.25c0-6.52,0-36.3,0-37.7
			C144.76,143.6,136.31,135.52,119.55,135.52z M133.66,175.79c0,12.9-8.2,18.09-18.45,18.09c-7.72,0-12.88-4.31-12.88-10.22
			c0-8.18,10.77-11.51,30.28-12.44c0.57-0.03,1.05,0.43,1.05,1V175.79z"
    />

    <path
      d="M195.98,137.08h-9.19c-0.55,0-1.03,0.35-1.2,0.88l-14.33,45.24c-0.22,0.7-1.21,0.7-1.44,0l-14.67-45.25
			c-0.17-0.52-0.65-0.87-1.19-0.87h-10.01c-0.87,0-1.48,0.86-1.18,1.68l22.44,62.11l-0.75,2.36c-3.14,9.16-5.42,9.83-12.17,9.83
			h-3.69c-0.69,0-1.26,0.56-1.26,1.26v7.5c0,0.69,0.56,1.26,1.26,1.26h2.47c14.71,0,18.33-4.34,23.76-19.66l22.34-64.66
			C197.45,137.93,196.84,137.08,195.98,137.08z"
    />

    <path
      d="M231.3,135.88c-8.62,0-14.83,3.18-18.9,8.67c-0.43,0.59-1.36,0.29-1.36-0.44v-24.58
			c0-0.69-0.56-1.26-1.26-1.26h-8.43c-0.69,0-1.26,0.56-1.26,1.26v81.18c0,0.69,0.56,1.26,1.26,1.26h8.43
			c0.69,0,1.26-0.56,1.26-1.26v-5.4c0-0.73,0.93-1.03,1.36-0.44c4.06,5.49,10.28,8.67,18.9,8.67c15.08,0,27.98-12.66,27.98-33.89
			C259.27,148.42,246.37,135.88,231.3,135.88z M228.4,193.04c-9.65,0-17.85-7.36-17.85-23.39c0-16.04,8.2-23.28,17.85-23.28
			c10.49,0,17.49,8.68,17.49,23.28C245.89,184.24,238.89,193.04,228.4,193.04z"
    />

    <path
      d="M294.93,136.88c-0.78-0.03-1.58-0.03-2.29-0.03c-7.18,0.11-12.98,3.36-16.57,9.1c-0.4,0.65-1.4,0.37-1.4-0.39
			v-7.21c0-0.69-0.56-1.26-1.26-1.26h-8.43c-0.69,0-1.26,0.56-1.26,1.26v62.37c0,0.69,0.56,1.26,1.26,1.26h8.67
			c0.69,0,1.26-0.56,1.26-1.26v-32.75c0-13.63,5.07-18.33,14.71-18.33h5.26c0.69,0,1.26-0.56,1.26-1.26l0-10.24
			C296.14,137.46,295.6,136.9,294.93,136.88z"
    />

    <path
      d="M373.91,118.27h-8.43c-0.69,0-1.26,0.56-1.26,1.26l0,24.58c0,0.73-0.93,1.03-1.36,0.44
			c-4.06-5.49-10.28-8.67-18.9-8.67c-15.08,0-27.98,12.54-27.98,33.77c0,21.22,12.9,33.89,27.98,33.89c8.62,0,14.83-3.18,18.9-8.67
			c0.43-0.59,1.36-0.29,1.36,0.44l0,5.4c0,0.69,0.56,1.26,1.26,1.26h8.43c0.69,0,1.26-0.56,1.26-1.26v-81.18
			C375.16,118.83,374.6,118.27,373.91,118.27z M346.85,193.04c-10.49,0-17.49-8.8-17.49-23.39c0-14.59,7-23.28,17.49-23.28
			c9.65,0,17.85,7.24,17.85,23.28C364.7,185.69,356.5,193.04,346.85,193.04z"
    />

    <path
      d="M436.94,137.08h-8.43c-0.69,0-1.26,0.56-1.26,1.26v5.76c0,0.73-0.93,1.03-1.36,0.44
			c-4.02-5.49-10.04-8.66-18.65-8.66c-15.08,0-27.62,11.82-27.62,32.44c0,20.74,12.54,32.56,27.62,32.56
			c8.61,0,14.63-3.17,18.65-8.66c0.43-0.59,1.36-0.29,1.36,0.44v4.36c0,6.75-1.33,10.01-3.86,12.78c-2.77,3.14-7.12,4.7-13.02,4.7
			c-9.02,0-12.74-3.13-14.24-8.48c-0.15-0.55-0.64-0.93-1.21-0.93l-10.43,0c-0.8,0-1.39,0.73-1.23,1.51
			c2.58,12.58,12.82,18.39,26.87,18.39c10.01,0,17.54-2.84,22.67-7.84c4.2-4.1,5.4-10.85,5.4-22.79v-56.03
			C438.19,137.65,437.63,137.08,436.94,137.08z M410.13,190.63c-10.49,0-17.12-8.2-17.12-22.31c0-13.99,6.63-22.07,17.12-22.07
			c9.65,0,17.61,6.63,17.61,22.07C427.73,183.75,419.77,190.63,410.13,190.63z"
    />

    <path
      d="M474.51,135.88c-18.45,0-31.96,13.39-31.96,33.53c0,18.81,11.46,34.13,32.32,34.13
			c15.9,0,25.68-9.3,28.9-19.64c0.15-0.49-0.21-0.98-0.72-0.98h-10.55c-0.67,0-1.27,0.38-1.57,0.97c-2.86,5.57-7.86,8.92-16.54,8.92
			c-9.92,0-17.13-6.89-18.79-18.27c-0.09-0.6,0.39-1.14,1-1.14h47.35c0.54,0,0.98-0.43,1-0.97
			C505.86,151.78,494.22,135.88,474.51,135.88z M490.8,163.49l-33.92,0c-0.62,0-1.1-0.56-0.99-1.18
			c2.08-11.56,9.05-16.19,18.5-16.19c9.86,0,16.59,7.25,17.42,16.28C491.86,162.99,491.39,163.49,490.8,163.49z"
    />

    <path
      d="M310.27,137.08h-8.43c-0.69,0-1.26,0.56-1.26,1.26v62.37c0,0.69,0.56,1.26,1.26,1.26h8.43
			c0.69,0,1.26-0.56,1.26-1.26v-62.37C311.53,137.65,310.97,137.08,310.27,137.08z"
    />

    <path
      d="M310.27,118.27h-8.43c-0.69,0-1.26,0.56-1.26,1.26v10.03c0,0.69,0.56,1.26,1.26,1.26h8.43
			c0.69,0,1.26-0.56,1.26-1.26v-10.03C311.53,118.83,310.97,118.27,310.27,118.27z"
    />
  </svg>
);
export default Logo;
