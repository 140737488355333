import React from "react";

export const container = (props) => {
  const { children, className } = props;
  return (
    // i need to add logic that only includes className if it's *not* undefined.
    <div className={`w-full h-full ${className}`}>
      <div className="mx-auto grid grid-cols-30">{children}</div>
    </div>
  );
};

export default container;
