import React from "react";

export const A1 = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div
      className={`xl:text-h1 md:text-h2 text-h3 tracking-tight antialiased ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};

A1.propTypes = {
  ...React.Component.propTypes,
};

export const A2 = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div
      className={`md:text-h2 text-h3 tracking-tight antialiased ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};

A2.propTypes = {
  ...React.Component.propTypes,
};

export const A3 = (props) => {
  const { children, className = "", ...rest } = props;
  return (
    <div
      className={`md:text-h3 text-h4 tracking-tight antialiased ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};

A3.propTypes = {
  ...React.Component.propTypes,
};

export const A4 = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div
      className={`md:text-h4 text-h5 tracking-tight antialiased ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};

A4.propTypes = {
  ...React.Component.propTypes,
};

export const A5 = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div
      className={`text-h5 tracking-tight antialiased ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};

A5.propTypes = {
  ...React.Component.propTypes,
};

export const A6 = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div className={`text-h6 antialiased ${className}`} {...rest}>
      {children}
    </div>
  );
};

A6.propTypes = {
  ...React.Component.propTypes,
};

export const A7 = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div className={`text-p antialiased ${className}`} {...rest}>
      {children}
    </div>
  );
};

A7.propTypes = {
  ...React.Component.propTypes,
};

export const A8 = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div className={`text-meta antialiased ${className}`} {...rest}>
      {children}
    </div>
  );
};

A8.propTypes = {
  ...React.Component.propTypes,
};
