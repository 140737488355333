import React from "react";
import { useLocation } from "@reach/router";
import { Symbol } from "./symbol";
import Container from "./container";
import { A8 } from "../components/fonts";

const nav2 = () => {
  const location = useLocation();
  const pathColors = [
    {
      path: "/",
      color: "text-wbSand",
      bg: "bg-wbBlue",
      border: "border-wbSand",
    },
    {
      path: "/platform",
      color: "text-wbTruss",
      bg: "bg-wbSand",
      border: "border-wbTruss",
    },
    {
      path: "/about",
      color: "text-wbStone",
      bg: "bg-wbTruss",
      border: "border-wbStone",
    },
    {
      path: "/careers",
      color: "text-wbSand",
      bg: "bg-wbStone50",
      border: "border-wbSand",
    },
    {
      path: "/open-roles",
      color: "text-wbTruss",
      bg: "bg-wbStone",
      border: "border-wbTruss",
    },
    {
      path: "/request-demo",
      color: "text-wbTruss",
      bg: "bg-wbSand",
      border: "border-wbTruss",
    },
    {
      path: "/intro-to-waybridge",
      color: "text-wbTruss",
      bg: "bg-wbSky",
      border: "border-wbTruss",
    },
    {
      path: "/press-releases/",
      color: "text-wbTruss",
      bg: "bg-wbSand",
      border: "border-wbTruss",
    },
    {
      path: "/blog/",
      color: "text-wbTruss",
      bg: "bg-wbSand",
      border: "border-wbTruss",
    },
  ];
  let pathColor = pathColors[0];

  const pathname = location.pathname;
  for (let i = 0; i < pathColors.length; i++) {
    const p = pathColors[i];
    if (pathname.match(p["path"] + ".*")) {
      pathColor = p;
    }
  }

  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <nav
      className={`fixed hover:${pathColor.bg} transition duration-300 mx-auto top-0 left-0 right-0 z-50`}
    >
      <Container>
        <div className="col-span-30 flex justify-between items-center flex-row px-6 md:pr-10 pt-2.5 pb-3">
          <div className="flex-1 text-wbTruss">
            <a href="/">
              <Symbol
                className={`fill-current ${pathColor.color} transform hover:-translate-x-1 transition duration-300 max-h-6 w-auto`}
              />
            </a>
          </div>
          <a className="flex-1" href="/platform">
            <A8
              className={`hidden sm:inline-block 
              ${pathColor.color} 
              border-b border-transparent hover:${pathColor.border} 
              transition duration-300 
              transform hover:-translate-x-1`}
            >
              Platform
            </A8>
          </a>
          <a className="flex-1" href="/about">
            <A8
              className={`hidden sm:inline-block 
              ${pathColor.color} 
              border-b border-transparent hover:${pathColor.border} 
              transition duration-300 
              transform hover:-translate-x-1`}
            >
              About
            </A8>
          </a>
          <a className="flex-1" href="/careers">
            <A8
              className={`hidden sm:inline-block 
              ${pathColor.color} 
              border-b border-transparent hover:${pathColor.border} 
              transition duration-300 
              transform hover:-translate-x-1`}
            >
              Careers
            </A8>
          </a>
          <a className="" href="/request-demo">
            <A8
              className={`hidden sm:inline-block ${pathColor.color}
              border-b border-transparent hover:${pathColor.border}
              transition duration-300
              transform hover:-translate-x-1`}
            >
              Demo
            </A8>
          </a>
          <div className="sm:hidden relative top-1 right-2 z-50">
            <button
              className="outline-none"
              onClick={() => setMenuOpen((open) => !open)}
            >
              <svg
                className={`${pathColor.color} w-6 h-6`}
                fill="none"
                strokeLinecap="square"
                strokeLinejoin="square"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </button>
          </div>
          <div
            className={`absolute top-0 left-0 w-full h-screen flex flex-col place-content-center ${
              pathColor.color
            } ${pathColor.bg} px-5 py-5 ${pathColor.border} ${
              menuOpen ? "" : "hidden"
            }`}
          >
            <a
              href="/"
              className={`antialiased inline-block ${pathColor.color} transition duration-300 text-h2 tracking-tight`}
            >
              Home
            </a>
            <a
              href="/platform"
              className={`antialiased inline-block ${pathColor.color} transition duration-300 text-h2 tracking-tight`}
            >
              Platform
            </a>
            <a
              href="/about"
              className={`antialiased inline-block ${pathColor.color} transition duration-300 text-h2 tracking-tight`}
            >
              About
            </a>
            <a
              href="/careers"
              className={`antialiased inline-block ${pathColor.color} transition duration-300 text-h2 tracking-tight`}
            >
              Careers
            </a>
            <a
              href="/request-demo"
              className={`antialiased inline-block ${pathColor.color} transition duration-300 text-h2 tracking-tight`}
            >
              Demo
            </a>
          </div>
          <div className=""></div>
        </div>
      </Container>
    </nav>
  );
};

export default nav2;
