import React from "react";
import Navigation from "./navigation";
import Footer from "./footer";
import SEO from "./seo";
import PropTypes from "prop-types";
import "../base.css";

const Layout = (props) => (
  <>
    <SEO title="Waybridge" />
    <Navigation />
    {props.hero}

    {props.children}
    <Footer />
  </>
);

Layout.propTypes = {
  hero: PropTypes.any,
  children: PropTypes.any,
};

export default Layout;
