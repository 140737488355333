import React from "react";

export const Symbol = (props) => {
  return (
    <svg
      fill="none"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="600"
      height="400"
      viewBox="0 0 600 400"
      xmlSpace="preserve"
      {...props}
    >
      <path
        className="st0"
        d="M597.82,80.93L451.52,1.35c-1.73-0.94-3.72-1.3-5.67-1.03L4.07,62.41c-2.06,0.29-3.59,2.05-3.59,4.13L0,313.4
				c-0.01,3.06,1.67,5.88,4.36,7.35l144.32,78.51c1.76,0.96,3.95,0.53,5.22-1.02l78.97-96.13c1.27-1.55,3.46-1.97,5.22-1.02
				l125.05,68.03c1.76,0.96,3.95,0.53,5.22-1.02l230.7-280.86C600.71,85.22,600.12,82.18,597.82,80.93z M517.93,79.99L408.16,95.42
				c-2.26,0.32-3.73-2.31-2.28-4.07l41.78-50.87c1.27-1.55,3.46-1.97,5.22-1.02l65.9,35.85C520.9,76.46,520.32,79.65,517.93,79.99z
				M191.43,121.49l41.79-50.87c1.27-1.55,3.46-1.97,5.22-1.02l65.9,35.85c2.12,1.15,1.54,4.34-0.85,4.68l-109.77,15.43
				C191.46,125.87,189.98,123.25,191.43,121.49z M355.34,95.81l-65.82-35.81c-2.12-1.15-1.54-4.34,0.85-4.68l109.64-15.41
				c2.26-0.32,3.73,2.31,2.28,4.07L360.56,94.8C359.29,96.34,357.1,96.77,355.34,95.81z M140.89,125.96L75.06,90.15
				c-2.12-1.15-1.54-4.34,0.85-4.68l109.66-15.41c2.26-0.32,3.73,2.31,2.28,4.07l-41.74,50.81
				C144.83,126.49,142.64,126.91,140.89,125.96z M120.77,155.78L37.4,257.27c-1.49,1.82-4.44,0.76-4.44-1.59l0.27-146.69
				c0-1.9,2.03-3.1,3.7-2.2l83.1,45.2C121.41,152.74,121.77,154.57,120.77,155.78z M127.57,348.51l-82.1-44.66
				c-1.38-0.75-1.74-2.57-0.74-3.79l82.37-100.27c1.49-1.82,4.44-0.76,4.44,1.59l-0.27,144.93
				C131.27,348.22,129.24,349.42,127.57,348.51z M168.04,162.32l146.83-20.64c2.26-0.32,3.73,2.31,2.28,4.07L168.59,326.74
				c-1.49,1.82-4.44,0.76-4.44-1.59l0.3-158.7C164.45,164.37,165.98,162.61,168.04,162.32z M382.5,132.18l146.94-20.65
				c2.26-0.32,3.73,2.31,2.28,4.07l-148.67,181c-1.49,1.82-4.44,0.76-4.44-1.59l0.3-158.7C378.91,134.23,380.44,132.47,382.5,132.18z
				M346,171.38l-0.27,144.8c0,1.9-2.03,3.1-3.7,2.2l-82.02-44.62c-1.38-0.75-1.74-2.57-0.74-3.79l82.29-100.18
				C343.06,167.96,346.01,169.02,346,171.38z"
      />
    </svg>
  );
};
