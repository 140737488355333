import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import Cookies from "js-cookie";

export const setTrackingCookie = (value) => {
  Cookies.set("ga-check", value, { expires: 365 });
};

export const startTracking = (location, consented) => {
  // if the user has not accepted consent then initiate GA
  // if they have/have not, respect it.s
  if (Cookies.get("CookieConsent") === undefined) {
    setTrackingCookie(true);
  } else {
    setTrackingCookie(consented);
  }
  if (!window.gatsbyPluginGDPRCookiesGoogleAnalyticsInitialized) {
    // only do this if not initiated, otherwise we double count
    initializeAndTrack(location);
  }

  if (consented) {
    let zi = document.createElement("script");
    zi.type = "text/javascript";
    zi.async = true;
    zi.referrerPolicy = "unsafe-url";
    zi.src = "https://ws.zoominfo.com/pixel/630794ce6639185b41ea3e6e";
    let s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(zi, s);
  }
};
